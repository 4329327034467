<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title class="mr-2">
      {{ heading }}
    </v-toolbar-title>

    <v-tooltip bottom v-if="!memoIsSent">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0 order-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('home')"
        >
          <v-icon>{{ iconBack }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.back-to-revision-list") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="memoIsSent">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0 order-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('home_sent')"
        >
          <v-icon>{{ iconBack }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.back-to-sent-list") }}</span>
    </v-tooltip>

    
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('reload')"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="!isDirectMemo">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0 order-1"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('print')"
        >
          <v-icon>{{ iconPrinter }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.print-preview") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="isExpress || pjpCreator">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0 order-1"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('revoke')"
        >
          <v-icon>{{ iconRevoke }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.revoke this memo") }}</span>
    </v-tooltip>

    <v-menu
      bottom
      left
      v-if="!memoIsSent && creatorCanEdit && !userIsKrani && !isDirectMemo"
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-2"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.other-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in actionMenu"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="menuAction(item.command)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu bottom left v-if="memoIsSent && isDirectMemo">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-2"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.other-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in directSentToolbarMenu"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="menuAction(item.command)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import toolbarMenu from "@/constants/toolbarmenu";
import {
  mdiReload,
  mdiDotsVertical,
  mdiSend,
  mdiDeleteAlertOutline,
  mdiElevatorUp,
  mdiBackburger,
  mdiCog,
  mdiCheckboxMarkedCircleOutline,
  mdiPrinter,
  mdiStepBackward,
} from "@mdi/js";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    creatorCanEdit: Boolean,
    actionMenu: { type: [Object, Array] },
  },
  data: () => ({
    iconBack: mdiBackburger,
    iconRevoke: mdiStepBackward,
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSend: mdiSend,
    iconDelete: mdiDeleteAlertOutline,
    iconSubmit: mdiElevatorUp,
    iconCog: mdiCog,
    iconPrinter: mdiPrinter,
    iconCheck: mdiCheckboxMarkedCircleOutline,
  }),

  computed: {
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", ["singleDraft"]),
    sentMenu() {
      if (!this.singleDraft) return [];
      return [];
      // if (!this.singleRevision.memo.created_by === this.currentUser.nik && parseInt(this.singleRevision))
      // toolbarMenu.sentDetailToolbarMenu,
      // sentDirectMenu: toolbarMenu.sentDirectDetailToolbarMenu,
    },
    userIsKrani() {
      if (!this.currentUser) return false;
      return ["KRANI"].includes(this.currentUser.level_aplikasi_elemen);
    },
    memoIsSent() {
      if (!this.singleDraft) return undefined;
      return parseInt(this.singleDraft.memo.memo_status_id) === 100;
    },
    memoIsPending() {
      if (!this.singleDraft) return undefined;
      return [25, 35, 45].includes(this.singleDraft.memo.memo_status_id);
    },
    isDirectMemo() {
      if (!this.singleDraft) return false;
      return (
        this.singleDraft.memo.created_by ===
          this.singleDraft.memo.approver_nik &&
        parseInt(this.singleDraft.memo.is_masuk) === 1
      );
    },
    isExpress() {
      if (!this.singleDraft) return false;
      return (
        this.singleDraft.memo.tipe === "masuk" &&
        this.singleDraft.memo.tag.includes("ekspres") &&
        this.singleDraft.memo.creator_nik === this.currentUser.nik &&
        parseInt(this.singleDraft.memo.memo_status_id) === 100
      );
    },
    userIsPjpDir() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS", "DIREKTUR"].includes(
        this.currentUser.level_aplikasi_elemen
      );
    },
    pjpCreator(){
      if (!this.singleDraft) return false;
      if (this.singleDraft.memo.creator_nik ===  this.currentUser.nik && this.userIsPjpDir) return true;
      return false;
    },
    directSentToolbarMenu() {
      if (!this.singleDraft) return [];
      return toolbarMenu.sentDirectDetailToolbarMenu;
    },
  },
  methods: {
    ...mapActions("draft", ["setSingleDraft"]),
    ...mapActions("inbox", ["setMemoValidationDialog"]),
    menuAction(command) {
      console.log(this.$route.name === "mysppd.detailmac");
      const isEksternal = this.singleDraft.memo.tipe === "eksternal";
      if (command === "home_sent" && isEksternal)
        return this.$emit("menuAction", "home_sentx");
      if (
        this.$route.name === "issuedsppd.detailmar" &&
        command === "home_sent"
      )
        return this.$emit("menuAction", "home_issuedsppd");
      if (
        this.$route.name === "issuedsppd.detailmac" &&
        command === "home_sent"
      )
        return this.$emit("menuAction", "home_issuedsppd");
      if (this.$route.name === "mysppd.detailmar" && command === "home_sent")
        return this.$emit("menuAction", "home_mysppd");
      if (this.$route.name === "mysppd.detailmac" && command === "home_sent")
        return this.$emit("menuAction", "home_mysppd");
      this.$emit("menuAction", command);
    },
    compareSlug(val1, val2) {
      if (!val1 || !val2) return;
      let res1 = val1.replace("&only=", "");
      let res2 = val2.replace("filter_", "");
      return res1 === res2;
    },
  },
};
</script>
